import store from "../../store/store";
import router from "../../router";

const channel = new BroadcastChannel("auth_channel");

channel.onmessage = async (event) => {
  const { action, data } = event.data;

  if (action === "login") {
    await store.dispatch("user/loginHandler", data);
    router.push({ path: "/" });
  } else if (action === "logout") {
    store.dispatch("user/logoutHandler");
  }
};

export function notifyAllTabs(action, data = {}) {
  channel.postMessage({ action, data });
}
