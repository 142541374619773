import request from "@/utils/request";

export function getUserList(data, signal) {
  return request({
    url: "/user/page",
    method: "post",
    data,
    signal,
  });
}

export function createUser(data) {
  return request({
    url: "/user/save",
    method: "post",
    data,
  });
}

export function updateUser(data) {
  return request({
    url: `/user/manageUserProfile/${data.id}`,
    method: "post",
    data,
  });
}

export function getPickUserList() {
  return request({
    url: "/user/getPickUserList",
    method: "get",
  });
}
export function getSalePersonList(data) {
  return request({
    url: "/user/getSalePersonList",
    method: "get",
    params: data,
  });
}

export function saveDefaultExpenseAndPayment(data) {
  return request({
    url: "/user/saveDefaultExpenseAndPayment",
    method: "post",
    data,
  });
}

// save fcm token
export function saveFCMToken(data) {
  return request({
    url: "/fcmToken/saveToken",
    method: "post",
    data,
  });
}
// delete fcm token
export function removeDBFCMToken(userID, fcmToken) {
  return request({
    url: `/fcmToken/removeToken/${userID}/${fcmToken}`,
    method: "delete",
  });
}
