import { debounce } from "../../utils/debounce";
import { onMakeChange, onModelChange, onSeriesChange } from "@/utils/common";

export default {
  data() {
    return {
      locationGroupOptions: [
        { locGroupDesc: "N/A", locGroupID: 0 },
        { locGroupDesc: "Discard", locGroupID: 2006 },
        { locGroupDesc: "Keep", locGroupID: 2005 },
      ],
      referredOptions: [],
      driverOptions: [],
      countryOption: [
        {
          id: 1,
          name: "New Zealand",
        },
      ],
      supplier: {
        isSupplierloading: false,
        supplierOptions: [],
      },
      location: {
        isLocationloading: false,
        locationOptions: [],
      },
      disposalDebtor: {
        isDisposalDebtorloading: false,
        disposalDebtorOptions: [],
      },
      getVehicleOptions: true,
      getPurchaseOptions: true,
      // to manage quere
      watcherQueue: Promise.resolve(),
    };
  },
  mounted() {
    if (this.getVehicleOptions) {
      this.getMakeOptions();
      this.getModelTypeOptions();
      this.getYearOptions();
      this.getPartTypeOptions();
      this.getColorOptions();
      this.getTrimColourOptions();
    }
    // this.getLocationGroupOptions()
    this.getDriverOptions();
    if (this.getPurchaseOptions) {
      this.getReferredOptions();
      this.getExpenseTypeOptions();
      this.getPaymentTypeOptions();
      this.getStockOptions();
      this.getStockPurchaseTypeOptions();
      this.getWriteOffOptions();
      this.getPlateOptions();
    }
  },
  methods: {
    async getLocationGroupOptions() {
      try {
        const result =
          await this.$api.vehicleAcquisition.getLocationGroupList();

        const { code, data } = result;
        if (code === 200) {
          this.locationGroupOptions = data;
        }
      } catch (err) {
        console.error(err.message);
      }
    },
    async getReferredOptions() {
      try {
        const result = await this.$api.common.getReferredList();

        const { code, data } = result;
        if (code === 200) {
          this.referredOptions = data;
        }
      } catch (err) {
        console.error(err.message);
      }
    },
    async getDriverOptions() {
      try {
        const result = await this.$api.common.getDriverList();

        const { code, data } = result;
        if (code === 200) {
          this.driverOptions = data;
        }
      } catch (err) {
        console.error(err.message);
      }
    },
    supplierChangeHandler(value) {
      const selectedPeople = this.supplier.supplierOptions.find(
        (res) => res.debtorId === value
      );
      // const obj = {
      //   firstName: selectedPeople.contactName,
      //   surName: selectedPeople.contactSurname,
      //   pickUpAddress: selectedPeople.address,
      //   suburb: selectedPeople.suburb,
      //   state: selectedPeople.state,
      //   postCode: selectedPeople.postCode,
      //   pickUpCountry: 1,
      //   ownerFirstName: selectedPeople.contactName,
      //   ownerSurName: selectedPeople.contactSurname,
      //   ownerAddress: selectedPeople.address,
      //   ownerSuburb: selectedPeople.suburb,
      //   ownerState: selectedPeople.state,
      //   ownerPostcode: selectedPeople.postCode,
      //   mobile: selectedPeople.mobile,
      //   ownerCountry: 1,
      //   licenseNo: selectedPeople.licenseNo,
      //   phone: selectedPeople.phone1
      // }
      // this.$set(this.form, "purchaseDetail", obj)
      // Purchased from and pickup
      this.form.purchaseDetail.contactId = selectedPeople.contactID;
      this.form.purchaseDetail.purchasedFrom = selectedPeople.company;
      this.form.purchaseDetail.firstName = selectedPeople.contactName;
      this.form.purchaseDetail.surName = selectedPeople.contactSurname;
      this.form.purchaseDetail.purchasePhone = selectedPeople.phone1;
      this.form.purchaseDetail.purchaseMobile = selectedPeople.mobile;
      this.form.purchaseDetail.pickUpAddress = selectedPeople.address;
      this.form.purchaseDetail.suburb = selectedPeople.suburb;
      this.form.purchaseDetail.state = selectedPeople.state;
      this.form.purchaseDetail.postCode = selectedPeople.postCode;

      // Owner infomation
      this.form.purchaseDetail.ownerContactId = selectedPeople.contactID;
      this.form.purchaseDetail.ownerFirstName = selectedPeople.contactName;
      this.form.purchaseDetail.ownerSurName = selectedPeople.contactSurname;
      this.form.purchaseDetail.phone = selectedPeople.phone1;
      this.form.purchaseDetail.mobile = selectedPeople.mobile;
      this.form.purchaseDetail.ownerEmail = selectedPeople.email;
      this.form.purchaseDetail.ownerAddress = selectedPeople.address;
      this.form.purchaseDetail.ownerSuburb = selectedPeople.suburb;
      this.form.purchaseDetail.ownerState = selectedPeople.state;
      this.form.purchaseDetail.ownerPostcode = selectedPeople.postCode;
      this.form.purchaseDetail.licenseNo = selectedPeople.licenseNo;
    },
    supplierSearchHandler: debounce(async function (event) {
      const { value } = event.target;
      this.getSuppliersOptions(value);
    }),
    async getSuppliersOptions(value) {
      this.supplier.isSupplierloading = true;

      if (value !== "") {
        try {
          const result = await this.$api.common.getContactList(value);
          const { code, data } = result;
          if (code === 200) {
            this.supplier.supplierOptions = data.records;
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        this.supplier.supplierOptions = [];
      }
      this.supplier.isSupplierloading = false;
    },
    locationSearchHandler: debounce(async function (event) {
      const { value } = event.target;
      this.getLocationOptions(value);
    }),
    async getLocationOptions(search) {
      this.location.isLocationloading = true;
      try {
        const result = await this.$api.common.getLocationList({
          locationCode: search,
          pageNum: 1,
          pageSize: 100,
        });
        const { code, data } = result;
        if (code === 200) {
          this.location.locationOptions = data.records;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.location.isLocationloading = false;
      }
    },
    disposalDebtorSearchHandler: debounce(async function (event) {
      const { value } = event.target;
      this.getDisposalDebtorOptions(value);
    }),
    async getDisposalDebtorOptions(value) {
      this.disposalDebtor.isDisposalDebtorloading = true;
      try {
        const result = await this.$api.common.getContactList(value);
        const { code, data } = result;
        if (code === 200) {
          this.disposalDebtor.disposalDebtorOptions = data.records;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.disposalDebtor.isDisposalDebtorloading = false;
      }
    },
    async handleMakeIdChange(newValue, oldValue) {
      this.form.makeDesc = onMakeChange(newValue, this.makeOptions);
      this.modelOptions = [];
      await this.getModelOptions(newValue);

      if (oldValue) {
        this.form.modelId = "";
        this.form.engineDesc = "";
        this.form.engineId = "";
        this.form.transmission = "";
        this.form.transId = "";
      }

      this.getEngineOptions(newValue);
      this.getTransimissionOptions(newValue);
      if (this.form.model) {
        this.form.modelId = this.modelOptions.find(
          (res) => res.name.toLowerCase() === this.form.model.toLowerCase()
        )?.id;
      }
    },
    async handleModelIdChange(newValue, oldValue) {
      if (oldValue) {
        this.seriesOptions = [];
        this.form.seriesId = "";
      }
      if (newValue) {
        this.form.model = onModelChange(newValue, this.modelOptions);
        await this.getSeriesOptions(this.form.makeId, newValue);
      } else {
        this.form.model = "";
      }
    },
    async handleSeriesIdChange(newValue) {
      if (newValue) {
        this.form.seriesDesc = onSeriesChange(newValue, this.seriesOptions);
      } else {
        this.form.seriesDesc = "";
      }
    },
  },
  watch: {
    "form.makeId": {
      async handler(newValue, oldValue) {
        if (this.disableWatchOptions) return;
        this.watcherQueue = this.watcherQueue.then(() =>
          this.handleMakeIdChange(newValue, oldValue)
        );
      },
      deep: true,
    },
    "form.modelId": {
      handler(newValue, oldValue) {
        if (this.disableWatchOptions) return;
        this.watcherQueue = this.watcherQueue.then(() =>
          this.handleModelIdChange(newValue, oldValue)
        );
      },
      deep: true,
    },
    "form.seriesId": {
      handler(newValue) {
        if (this.disableWatchOptions) return;
        this.watcherQueue = this.watcherQueue.then(() =>
          this.handleSeriesIdChange(newValue)
        );
      },
    },
  },
};
