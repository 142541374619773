import firebaseApp from "../firebaseConfig";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { saveFCMToken } from "../../../api/user";
import store from "../../../store/store";

const storageKey = "fcm-token";
const messaging = getMessaging(firebaseApp);
const vapidKey = process.env.VUE_APP_FIREBASE_CLOUD_MESSAGING_PUBLIC_KEY;

async function getFCMToken(retries = 5) {
  if (!messaging) return null; // Return null if messaging is not initialized
  try {
    const token = await getToken(messaging, {
      vapidKey: vapidKey,
    });
    console.log(token);

    return token; // Return the retrieved token
  } catch (error) {
    if (retries > 0) {
      console.warn("Retrying getToken, attempts left:", retries);
      return new Promise((resolve) =>
        setTimeout(() => resolve(getFCMToken(retries - 1)), 1000)
      );
    } else {
      console.error("Error retrieving FCM token:", error);
      return null; // Return null in case of error
    }
  }
}

// Function to receive messages
function receiveMessage(callback) {
  if (!messaging) return; // Check if messaging is initialized
  onMessage(messaging, (payload) => {
    console.log("Message received: ", payload); // Handle the received message
    if (callback) {
      callback(payload); // Call the callback function to handle message
    }
  });
}

// Function to initialize FCM and get the user token
async function initFCM() {
  const user = store.getters.userInfo;
  const existingToken = getCurrentFCMToken();
  if (!user.token || existingToken) return;
  console.log("getToken is running");
  const fcmToken = await getFCMToken();
  if (!fcmToken) return; // Return null if no token is received
  store.dispatch("user/setFCMToken", fcmToken);
  try {
    await saveFCMToken({
      userID: user.userId,
      fcmToken: fcmToken,
    });
  } catch (error) {
    throw new Error(error);
  }

  return fcmToken; // Return the FCM token
}

async function removeFCMToken() {
  await store.dispatch("user/removeFCMToken");
}

async function checkNotificationPermission(permission) {
  if (permission === "granted") {
    await initFCM();
  } else if (permission !== "granted") {
    await removeFCMToken();
    removeStorageFCMToken();
  }
}

function getCurrentFCMToken() {
  return localStorage.getItem(storageKey);
}

function setFCMToken(token) {
  localStorage.setItem(storageKey, token);
}

function removeStorageFCMToken() {
  localStorage.removeItem("fcm-token");
}

export {
  messaging,
  receiveMessage,
  getToken,
  initFCM,
  checkNotificationPermission,
  getCurrentFCMToken,
  setFCMToken,
  removeStorageFCMToken,
};
