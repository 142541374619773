import { getUserNotification, markAsRead } from "../../api/notification";

const state = () => {
  return {
    notifications: [],
    unreadMessages: 0,
  };
};
const mutations = {
  SET_NOTIFICATION(state, notifications) {
    state.notifications.unshift(notifications);
    state.unreadMessages += 1;
  },
  SET_LOAD_NOTIFICATIONS(state, notifications) {
    state.notifications.push(...notifications);
    state.unreadMessages = state.notifications.filter((n) => !n.isRead).length;
  },
  MARK_AS_READ(state, notificationId) {
    const index = state.notifications.findIndex(
      (n) => n.notificationID === notificationId
    );
    if (index !== -1 && !state.notifications[index].isRead) {
      state.notifications[index].isRead = true;
      state.unreadMessages -= 1;
    }
  },
  CLEAR_NOTIFICATIONS(state) {
    state.notifications = [];
    state.unreadMessages = 0;
  },
  // custom library
  SET_LOADING(state, isLoading) {
    if (isLoading) {
      state.notifications.push({ isLoading: true });
    } else {
      state.notifications = state.notifications.filter(
        (notification) => !notification.isLoading
      );
    }
  },
};
const actions = {
  async getMessages({ commit, rootState }, payload) {
    commit("SET_LOADING", true);
    try {
      const userId = rootState.user.userId;
      const res = await getUserNotification(userId, payload);
      const { code, data } = res;
      if (code === 200) {
        commit("SET_LOADING", false);
        commit("SET_LOAD_NOTIFICATIONS", data.records);
        return data;
      }
    } catch (error) {
      commit("SET_LOADING", false);
      throw new Error(error);
    }
  },
  async markAsReadNoti({ commit }, payload) {
    try {
      commit("MARK_AS_READ", payload);
      await markAsRead(payload);
    } catch (error) {
      throw new Error(error);
    }
  },
  clearNotifications({ commit }) {
    commit("CLEAR_NOTIFICATIONS");
  },
};
const getters = {
  unreadMessages: (state) => state.unreadMessages,
  notifications: (state) => state.notifications,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
