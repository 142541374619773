import request from "@/utils/request";

export function getUserNotification(userID, data) {
  return request({
    url: `/mgr/notification/getUserNotification/${userID}`,
    method: "get",
    params: data,
  });
}

export function markAsRead(notificationID) {
  return request({
    url: `/mgr/notification/markAsRead/${notificationID}`,
    method: "put",
  });
}
