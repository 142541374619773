import { apiService } from "../../services/api-service";
import { getRoleList } from "@/api/role";
import stompClient from "../../services/stomp/stompClient";
import { setToken, getToken, removeToken, parseJwt } from "../../utils/auth";
import {
  checkNotificationPermission,
  getCurrentFCMToken,
  setFCMToken,
  removeStorageFCMToken,
} from "../../services/firebase/cloud-messaging/firebase-messaging";
import moment from "moment";

let timer = "";

const state = () => {
  return {
    token: getToken("ezipart-token") || null,
    userId: Number(getToken("ezipart-userId")) || null,
    userName: localStorage.getItem("ezipart-userName", "") || null,
    usersName: localStorage.getItem("ezipart-usersName", "") || null,
    active: localStorage.getItem("user-active", "") || null,
    postCode: localStorage.getItem("user-postCode", "") || null,
    streetAddress: localStorage.getItem("user-address", "") || null,
    suburb: localStorage.getItem("user-suburb", "") || null,
    permission: localStorage.getItem("user-permission") || [],
    role: localStorage.getItem("user-role") || null,
    fcmToken: getCurrentFCMToken() || null,
    isConnectedToXero: false,
    isLogout: false,
  };
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_USERNAME: (state, name) => {
    state.userName = name;
  },
  SET_USERSNAME: (state, name) => {
    state.usersName = name;
  },
  SET_USERID: (state, userId) => {
    state.userId = userId;
  },
  SET_USER_ACTIVE: (state, userActive) => {
    state.userInfo = userActive;
  },
  SET_USER_POSTCODE: (state, postCode) => {
    state.userInfo = postCode;
  },
  SET_USER_ADDRESS: (state, streetAddress) => {
    state.userInfo = streetAddress;
  },
  SET_USER_SUBURB: (state, suburb) => {
    state.userInfo = suburb;
  },
  SET_USER_PERMISSION: (state, permission) => {
    state.permission = permission;
  },
  SET_USER_ROLE: (state, role) => {
    state.role = role;
  },
  SET_FCM_TOKEN: (state, fcmToken) => {
    setFCMToken(fcmToken);
    state.fcmToken = fcmToken;
  },
  SET_USER_CONNECTED: (state, connected) => {
    state.isConnectedToXero = connected;
  },
  SET_USER_LOGIN: (state) => {
    state.isLogout = false;
  },
  SET_USER_LOGOUT: (state) => {
    state.isLogout = true;
  },
};

const actions = {
  loginHandler({ dispatch, state }, userInfo) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const result = await apiService.auth.login(userInfo);

          const { code, data, msg } = result;
          if (code === 200) {
            dispatch("setUserInfo", data);
            await dispatch("getAllRoles");

            resolve({ access: true, msg });

            await dispatch("stompClient/connectWS", null, { root: true });
            checkNotificationPermission(Notification.permission, state);

            dispatch("setAutoLogout", data.accessToken);
          } else {
            reject("Login Fail");
          }
        } catch (err) {
          dispatch("clearUserInfo");
          reject(err.message);
        }
      })();
    });
  },

  async getAllRoles() {
    try {
      const res = await getRoleList();
      const { code, data } = res;
      if (code === 200) {
        const roles = data.map((role) => role.roleName);
        localStorage.setItem("roles", JSON.stringify(roles));
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  setUserInfo({ commit }, data) {
    commit("SET_USER_LOGIN");
    commit("SET_TOKEN", data.accessToken);
    commit("SET_USERNAME", data.userInfo.userName);
    commit("SET_USERSNAME", data.userInfo.usersName);
    commit("SET_USERID", data.userInfo.userID);
    commit("SET_USER_ACTIVE", data.userInfo.active);
    commit("SET_USER_POSTCODE", data.userInfo.postCode);
    commit("SET_USER_ADDRESS", data.userInfo.streetAddress);
    commit("SET_USER_SUBURB", data.userInfo.suburb);
    commit("SET_USER_PERMISSION", data.userInfo.permission);
    commit("SET_USER_ROLE", data.userInfo.role);

    setToken("ezipart-token", data.accessToken);
    setToken("ezipart-userId", data.userInfo.userID);

    localStorage.setItem("ezipart-userId", data.userInfo.userID);
    localStorage.setItem("ezipart-userName", data.userInfo.userName);
    localStorage.setItem("ezipart-usersName", data.userInfo.usersName);
    localStorage.setItem("user-active", data.userInfo.active);
    localStorage.setItem("user-postCode", data.userInfo.postCode);
    localStorage.setItem("user-address", data.userInfo.streetAddress);
    localStorage.setItem("user-suburb", data.userInfo.suburb);
    localStorage.setItem("user-permission", data.userInfo.permission);
    localStorage.setItem("user-role", data.userInfo.role);
  },

  setFCMToken({ commit }, fcmToken) {
    commit("SET_FCM_TOKEN", fcmToken);
  },

  async removeFCMToken({ state, dispatch }) {
    const userId = JSON.parse(localStorage.getItem("ezipart-userId"));
    try {
      await stompClient.send("/app/fcmToken/removeToken", {
        userID: userId,
        fcmToken: state.fcmToken,
      });
    } catch (error) {
      console.log("Reconnecting...");
      try {
        await dispatch(
          "stompClient/reconnectWS",
          async () => {
            await stompClient.send("/app/fcmToken/removeToken", {
              userID: userId,
              fcmToken: state.fcmToken,
            });
            console.log("remove fcm token success");
          },
          { root: true }
        );
      } catch (error) {
        console.log("Error removing FCM Token");
      }
    }
  },

  setAutoLogout({ dispatch }, accessToken) {
    // token (auto logout)
    const currentTime = moment();
    const token = accessToken.split(" ")[1];
    const decodedToken = parseJwt(token);
    const expirationTime = moment
      .unix(decodedToken.exp)
      .diff(currentTime, "milliseconds");
    timer = setTimeout(() => {
      dispatch("logoutHandler");
    }, expirationTime);
  },

  clearUserInfo({ commit }) {
    commit("SET_FCM_TOKEN", null);
    removeStorageFCMToken();
    localStorage.removeItem("user-permission");
    localStorage.removeItem("user-role");
    localStorage.removeItem("tabs-va");
    localStorage.removeItem("tabs-vehicle");
    localStorage.removeItem("tabs-invoice");
    localStorage.removeItem("tabs-po");
    localStorage.removeItem("latestPages");
    localStorage.removeItem("ezipart-userName");
    localStorage.removeItem("ezipart-usersName");
    localStorage.removeItem("ezipart-userId");
    localStorage.removeItem("user-active");
    localStorage.removeItem("user-address");
    localStorage.removeItem("user-postCode");
    localStorage.removeItem("user-suburb");
    localStorage.removeItem("roles");

    removeToken("ezipart-token");
    removeToken("ezipart-userId");
  },

  async logoutHandler({ commit, dispatch }) {
    await dispatch("removeFCMToken");
    dispatch("stompClient/disconnectWS", null, { root: true });

    dispatch("clearUserInfo");

    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    commit("SET_USER_LOGOUT");
  },

  async checkConnectionToXero({ commit }) {
    try {
      const result = await apiService.setting.checkConnectedXeroAccount();
      if (result.code === 200) {
        commit("SET_USER_CONNECTED", result.data);
      }
    } catch (e) {
      console.log(e);
    }
  },
};
const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
