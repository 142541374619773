export default {
  methods: {
    pushItemToLocalStorageArray(item, key) {
      let storedArray = JSON.parse(localStorage.getItem(key));

      if (storedArray === null) {
        storedArray = [];
      }

      const existItem = this.checkIfItemExists(
        storedArray,
        (itemExist) => itemExist.tabValue === item.tabValue
      );
      if (!existItem) {
        storedArray.push(item);

        const updatedArrayString = JSON.stringify(storedArray);
        localStorage.setItem(key, updatedArrayString);
      }
    },
    checkIfItemExists(array, conditionFn) {
      return array.some(conditionFn);
    },
    removeItemFromLocalStorageArray(conditionFn, key) {
      let storedArray = localStorage.getItem(key);

      if (storedArray === null) {
        storedArray = [];
      } else {
        storedArray = JSON.parse(storedArray);
      }

      storedArray = storedArray.filter((item) => !conditionFn(item));

      const updatedArrayString = JSON.stringify(storedArray);
      localStorage.setItem(key, updatedArrayString);
    },
    storeTabActive(tabValue, toggle) {
      this.$set(this.toggles, tabValue, toggle);
    },
    activateScrollTab(tabValue) {
      if (this.toggles[tabValue]) {
        this.toggles[tabValue]();
      }
    },
  },
};
