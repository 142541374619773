import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user-store";
import invoiceModule from "./modules/invoice";
import stock from "./modules/stock";
import inventory from "./modules/inventory";
import carRemoval from "./modules/car-removal";
import purchaseOrder from "./modules/purchase-order";
import globalSearch from "./modules/global-search";
import picking from "./modules/picking";
import dispatch from "./modules/dispatch";
import stompClient from "./modules/stomp-client";
import notification from "./modules/notification";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    invoice: invoiceModule,
    stock,
    inventory,
    carRemoval,
    purchaseOrder,
    globalSearch,
    picking,
    dispatch,
    stompClient,
    notification,
  },
  state: {
    Sidebar_drawer: null,
    Customizer_drawer: false,
    SidebarColor: "white", //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
    SidebarBg: "",
    navbarColor: "primary",
    setHorizontalLayout: false, // Horizontal layout
  },
  mutations: {
    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload;
    },
    SET_CUSTOMIZER_DRAWER(state, payload) {
      state.Customizer_drawer = payload;
    },
    SET_SIDEBAR_COLOR(state, payload) {
      state.SidebarColor = payload;
    },
    SET_NAVBAR_COLOR(state, payload) {
      state.navbarColor = payload;
    },
    SET_LAYOUT(state, payload) {
      state.setHorizontalLayout = payload;
    },
  },
  actions: {},
  getters: {
    userInfo: (state) => state.user,
  },
});
