import request from "@/utils/request";

export function getPicker(data) {
  return request({
    url: "/mobile/picking/getPickerList",
    method: "get",
    params: data,
  });
}

export function getDispatcher() {
  return request({
    url: "/mobile/dispatch/getDispatcherList",
    method: "get",
  });
}

export function handleAssignDispatcher(invoiceID, data) {
  return request({
    url: `/mgr/invoices/assignDispatcher/${invoiceID}`,
    method: "put",
    data,
  });
}

export function getDeliveryDriver() {
  return request({
    url: "/mobile/delivery/getDeliveryDriverList",
    method: "get",
  });
}

export function handleAssignDeliveryDriver(invoiceID, data) {
  return request({
    url: `/mobile/delivery/assignDeliveryDriver/${invoiceID}`,
    method: "put",
    data,
  });
}
