import stompClient from "../../services/stomp/stompClient";

let timer = null;

const state = () => {
  return {
    connected: false,
  };
};

const mutations = {
  SET_CONNECTED: (state, status) => {
    state.connected = status;
  },
};

const actions = {
  async connectWS() {
    await stompClient.connect(() => {
      console.log("Connect socket successfully!");
    });
  },

  async reconnectWS({ dispatch }, callback) {
    if (!timer)
      timer = setTimeout(() => {
        timer = null;
        throw new Error("Timeout on connect");
      }, 3000);
    try {
      await stompClient.connect(callback);
      clearTimeout(timer);
      timer = null;
    } catch (error) {
      setTimeout(() => dispatch("reConnectWS", callback), 1000);
    }
  },

  disconnectWS() {
    stompClient.disconnect();
  },
};

const getters = {
  getStompConnect: (state) => state.connected,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
